import React from "react";

const LetterM = () => {
  return (
    <svg
      width="970"
      height="351"
      viewBox="0 0 970 351"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M1319.28 17.0577C1319.26 12.5318 1317.45 8.1974 1314.24 5.00135C1311.04 1.8053 1306.7 0.00727375 1302.17 0H1242.18C1235.77 0 1231.48 1.4584 1227.9 6.46127L1035.26 199.025V17.0762C1035.26 7.84582 1027.39 0.0184608 1016.7 0.0184608H956.856C951.816 0.0184608 948.281 1.47686 946.168 3.59985L704.332 245.796V17.0577C704.312 12.5286 702.5 8.19142 699.291 4.99485C696.082 1.79827 691.738 0.00239824 687.209 0H627.212C620.806 0 616.514 1.4584 612.932 6.46127L420.285 198.979V17.0762C420.276 7.88275 412.402 0.0184608 401.695 0.0184608H341.873C336.833 0.0184608 333.298 1.47686 331.175 3.59985L3.12728 332.164C1.62884 333.676 0.610891 335.597 0.201497 337.686C-0.207898 339.774 0.00954931 341.937 0.826481 343.903C1.64341 345.868 3.02334 347.548 4.79265 348.731C6.56197 349.914 8.64164 350.547 10.77 350.551L118.258 350.496C124.664 350.496 130.368 348.382 133.949 344.136L326.052 151.784V334.094C326.052 344.792 331.803 350.487 343.156 350.487H402.434C405.774 350.591 409.088 349.866 412.081 348.378C415.073 346.89 417.651 344.684 419.584 341.958L610.099 151.194V334.075C610.099 343.306 614.308 348.779 622.781 350.136C623.74 350.404 624.73 350.544 625.726 350.551L733.213 350.496C739.619 350.496 745.324 348.382 748.905 344.136L941.008 151.784V334.094C941.008 344.792 946.758 350.487 958.112 350.487H1017.36C1020.7 350.591 1024.02 349.866 1027.01 348.378C1030 346.89 1032.58 344.684 1034.51 341.958L1225.05 151.194V334.075C1225.05 344.773 1230.7 350.478 1242.15 350.478H1319.27L1319.28 17.0577Z"
        fill="white"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default LetterM;
