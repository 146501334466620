import axios from "axios";

const url = "https://devapi.medex.me/api/Medex";
// const url = "https://api.medex.me/api/Medex";

let headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getWeeklyData = () => {
  return axios({
    method: "get",
    url: `${url}/week`,
    headers: headers,
  });
};
export const getDataByDate = (date) => {
  return axios({
    method: "get",
    url: `${url}/bydate/${date}`,
    headers: headers,
  });
};
