import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
// import { useScroll } from "framer-motion";

import "./ChargerTest.scss";
import { gsap } from "gsap";

const ChargerTest = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const [airpods, setAirpods] = useState({ frame: 0 });
  const [overlap, setOverlap] = useState(true);
  const [widthWin, setWidthWin] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [oldScrollY, setOldScrollY] = useState(0);

  const canvasRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setScrolled(false);
      setOldScrollY(0);
      setOverlap(true);
    }, 500);
  }, []);

  useEffect(() => {
    const images = [];
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = 1070;
    canvas.height = 1000;

    const frameCount = 175;
    const currentFrame = (index) => `/car-animation/${index + 1}.webp`;

    for (let i = 0; i < frameCount; i++) {
      const img = new Image();
      img.src = currentFrame(i);
      images.push(img);
    }

    function render(i) {
      context.clearRect(0, 0, canvas.width, canvas.height);

      context.drawImage(images[i], 0, 0);
    }

    var someDiv = document.querySelector(".ev-charger-wrapper");
    const first = document.getElementById("animation-title-1");
    const second = document.getElementById("animation-title-2");
    const third = document.getElementById("animation-title-3");

    const handleScroll = () => {
      var distanceToTop = someDiv.getBoundingClientRect().top;
      if (distanceToTop < 50) {
        if (!scrolled) {
          setOldScrollY(window.scrollY);
          setScrolled(true);
        }
      }
      if (oldScrollY !== 0) {
        if (
          Math.round((window.scrollY - oldScrollY) / 10) <= 174 &&
          Math.round((window.scrollY - oldScrollY) / 10) > 0
        ) {
          setAirpods({
            frame: Math.round((window.scrollY - oldScrollY) / 10),
          });
          render(Math.round((window.scrollY - oldScrollY) / 10));
        } else if (Math.round((window.scrollY - oldScrollY) / 10) <= 0) {
          setAirpods({
            frame: 1,
          });
          render(1);
        } else {
          setAirpods({
            frame: 174,
          });
          render(174);
        }
        if (!overlap) {
          first.style.opacity = `${
            (window.scrollY - oldScrollY) / 1000 - 0.3
          } !important`;
          second.style.opacity = `${
            (window.scrollY - oldScrollY) / 1000 - 0.5
          } !important`;
          third.style.opacity = `${
            (window.scrollY - oldScrollY) / 1000 - 0.7
          } !important`;
        }
      } else {
        setAirpods({
          frame: 1,
        });
        render(1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    images[0].onload = render;

    return () => {
      window.removeEventListener("scroll", handleScroll);
      gsap.killTweensOf(airpods); // Cleanup animation on unmount
    };
  }, [oldScrollY, scrolled]);

  function elementsOverlap(el1, el2) {
    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    return !(
      domRect1.top > domRect2.bottom ||
      domRect1.right < domRect2.left ||
      domRect1.bottom < domRect2.top ||
      domRect1.left > domRect2.right
    );
  }
  useEffect(() => {
    const title = document.querySelector("#animation-title-3");
    const img = document.querySelector("#car-animation-canvas");
    const first = document.getElementById("animation-title-1");
    const second = document.getElementById("animation-title-2");
    const third = document.getElementById("animation-title-3");
    function handleResize() {
      setWidthWin(window.innerWidth);
      if (scrolled) {
        if (elementsOverlap(title, img)) {
          setOverlap(true);
        } else {
          setOverlap(false);
        }
      }
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [widthWin, scrolled]);

  return (
    <div className="ev-charger-wrapper">
      <div className="glow">
        <div className="ev-charger-container">
          <h1 className="primary-heading">ev charger</h1>
          <div className="ev-charger-content-wrapper">
            <div className="left">
              <p>
                Medex is building Montenegro's EV charger grid, making electric
                mobility accessible to all. With our innovative approach to
                electric energy, we ensure seamless processes.
              </p>
              <p>
                Experience the power of genuine green energy with us - every MWh
                generated comes from 100% renewable sources.
              </p>
              <h5
                style={{
                  opacity: overlap
                    ? "0"
                    : (window.scrollY - oldScrollY) / 1000 - 0.3,
                }}
                ref={ref1}
                id="animation-title-1"
                className="animation-title"
              >
                Easy operation
              </h5>
              <h5
                style={{
                  opacity: overlap
                    ? "0"
                    : (window.scrollY - oldScrollY) / 1000 - 0.5,
                }}
                ref={ref2}
                id="animation-title-2"
                className="animation-title"
              >
                guarantee of origin
              </h5>
              <h5
                style={{
                  opacity: overlap
                    ? "0"
                    : (window.scrollY - oldScrollY) / 1000 - 0.7,
                }}
                ref={ref3}
                id="animation-title-3"
                className="animation-title"
              >
                fast charging system
              </h5>
            </div>
            <div className="right" id="car-animation">
              <canvas ref={canvasRef} id="car-animation-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChargerTest;

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
