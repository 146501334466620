import React from "react";

const Star = () => {
  return (
    <svg
      width="54"
      height="51"
      viewBox="0 0 54 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.0979 1.92899C25.6966 0.0863638 28.3034 0.08637 28.9021 1.92899L33.7354 16.8044C34.0032 17.6284 34.7711 18.1863 35.6375 18.1863H51.2784C53.2159 18.1863 54.0214 20.6656 52.454 21.8044L39.8002 30.9979C39.0993 31.5072 38.806 32.4099 39.0737 33.2339L43.907 48.1093C44.5057 49.9519 42.3968 51.4842 40.8293 50.3454L28.1756 41.1519C27.4746 40.6426 26.5254 40.6426 25.8244 41.1519L13.1707 50.3454C11.6032 51.4842 9.49428 49.952 10.093 48.1093L14.9263 33.2339C15.194 32.4099 14.9007 31.5072 14.1997 30.9979L1.54598 21.8044C-0.0214458 20.6656 0.784111 18.1863 2.72156 18.1863H18.3625C19.2289 18.1863 19.9968 17.6284 20.2646 16.8044L25.0979 1.92899Z"
        fill="#24DC8F"
      />
    </svg>
  );
};

export default Star;
