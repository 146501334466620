import React, { createRef, useEffect, useRef, useState } from "react";
import "./Video.scss";
import ReactPlayer from "react-player/lazy";
import Play from "../../../assets/svgs/Play";
import horses from "../../../assets/video-preview.png";

const Video = () => {
  const [play, setPlay] = useState(false);
  const [controls, setControls] = useState(false);

  const playVideo = (e) => {
    setPlay(true);
    setControls(true);
    document.getElementById("video-overlay").style.zIndex = "-5";
    document.getElementById("video-overlay").style.opacity = "0";
  };
  const closeVideo = () => {
    setPlay(false);
    setControls(false);
    preview.current.seekTo(0, "seconds");

    document.getElementById("video-overlay").style.zIndex = "100";
    document.getElementById("video-overlay").style.opacity = "1";
  };

  const preview = createRef(null);

  return (
    <div className="video-wrapper">
      <div id="video-container" className="video-container">
        <ReactPlayer
          ref={preview}
          width={"100%"}
          height={"100%"}
          style={{ aspectRatio: "1.78" }}
          url="videos/video.mp4"
          controls={controls}
          playing={play}
          playIcon={<Play />}
          autoplay
          config={{ file: { attributes: { poster: horses } } }}
          playsinline
          onEnded={() => {
            closeVideo();
          }}
        />
      </div>
      <div id="video-overlay" className="video-overlay">
        <div className="content">
          <h1>The Medex story</h1>
          <p>
            Take a look at our one minute video to see our story and learn how
            Medex brings results for you.
          </p>
          <button onClick={playVideo} id="video-play-btn">
            <Play />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Video;
