import React, { useState, useEffect } from "react";
import Arrow from "../../../../assets/svgs/Arrow";
import Loader from "../../UI/Loader/Loader";
import "./DayAhead.scss";
import data from "../../../services/testData.json";
import { getWeeklyData } from "../../../services/getData";
import dayjs from "dayjs";

const DayAhead = () => {
  const [weekData, setWeekData] = useState([]);
  const sortDate = (arr) => {
    arr.sort(function (a, b) {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return c - d;
    });
    return arr;
  };

  useEffect(() => {
    getWeeklyData().then((data) => {
      setWeekData(sortDate(data.data));

      setCurrDate(data.data.length - 1);
    });
  }, []);

  const [currDate, setCurrDate] = useState(0);

  const nextDate = () => {
    if (currDate + 1 === weekData.length) return;
    setCurrDate((prev) => prev + 1);
  };

  const prevDate = () => {
    if (currDate - 2 < 0) return;
    setCurrDate((prev) => prev - 1);
  };

  const calculatePercentage = (newPrice, oldPrice) => {
    if (newPrice > oldPrice) {
      return (((newPrice - oldPrice) / oldPrice) * 100).toFixed(2) + "%";
    } else if (oldPrice > newPrice) {
      return (((oldPrice - newPrice) / oldPrice) * 100).toFixed(2) * -1 + "%";
    } else return "";
  };

  if (weekData.length > 0)
    return (
      <div className="day-ahead-wrapper">
        {/* <Loader /> */}
        <div className="day-ahead-container">
          <h3>Day-ahead market</h3>
          <table>
            <tr id="change-date">
              <td></td>
              <td id="day-ahead-left">
                {weekData.length > 1 && (
                  <button onClick={prevDate}>
                    <Arrow />
                  </button>
                )}
                <span>
                  {weekData.length > 1
                    ? new Date(weekData[currDate - 1].date).toLocaleDateString(
                        "en-GB",
                        {
                          day: "numeric",
                          month: "short",
                        }
                      )
                    : ""}
                </span>
              </td>
              <td id="day-ahead-right">
                <span>
                  {new Date(weekData[currDate].date).toLocaleDateString(
                    "en-GB",
                    {
                      day: "numeric",
                      month: "short",
                    }
                  )}
                </span>
                <button onClick={nextDate}>
                  <Arrow />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <h4>Baseload Price</h4>
              </td>
              <td style={{ position: "relative" }}>
                <p>
                  {weekData.length > 1
                    ? (
                        Math.round(weekData[currDate - 1].baseloadPrice * 100) /
                        100
                      ).toFixed(2)
                    : ""}
                </p>
                {weekData.length > 1 && currDate - 2 >= 0 && (
                  <span
                    className={`${
                      calculatePercentage(
                        weekData[currDate - 1].baseloadPrice,
                        weekData[currDate - 2].baseloadPrice
                      ).includes("-")
                        ? "red left"
                        : "green left"
                    }`}
                  >
                    {calculatePercentage(
                      weekData[currDate - 1].baseloadPrice,
                      weekData[currDate - 2].baseloadPrice
                    )}
                  </span>
                )}
              </td>
              <td>
                <p>
                  {(
                    Math.round(weekData[currDate].baseloadPrice * 100) / 100
                  ).toFixed(2)}
                </p>
                {weekData.length > 1 && currDate - 1 >= 0 && (
                  <span
                    className={`${
                      calculatePercentage(
                        weekData[currDate].baseloadPrice,
                        weekData[currDate - 1].baseloadPrice
                      ).includes("-")
                        ? "red right"
                        : "green right"
                    }`}
                  >
                    {calculatePercentage(
                      weekData[currDate].baseloadPrice,
                      weekData[currDate - 1].baseloadPrice
                    )}
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <h4>Peakload Price</h4>
              </td>
              <td style={{ position: "relative" }}>
                <p>
                  {weekData.length > 1
                    ? (
                        Math.round(weekData[currDate - 1].peakLoadPrice * 100) /
                        100
                      ).toFixed(2)
                    : ""}
                </p>{" "}
                {weekData.length > 1 && currDate - 2 >= 0 && (
                  <span
                    className={`${
                      calculatePercentage(
                        weekData[currDate - 1].peakLoadPrice,
                        weekData[currDate - 2].peakLoadPrice
                      ).includes("-")
                        ? "red left"
                        : "green left"
                    }`}
                  >
                    {calculatePercentage(
                      weekData[currDate - 1].peakLoadPrice,
                      weekData[currDate - 2].peakLoadPrice
                    )}
                  </span>
                )}
              </td>
              <td>
                <p>
                  {(
                    Math.round(weekData[currDate].peakLoadPrice * 100) / 100
                  ).toFixed(2)}
                </p>
                {weekData.length > 1 && currDate - 1 >= 0 && (
                  <span
                    className={`${
                      calculatePercentage(
                        weekData[currDate].peakLoadPrice,
                        weekData[currDate - 1].peakLoadPrice
                      ).includes("-")
                        ? "red right"
                        : "green right"
                    }`}
                  >
                    {calculatePercentage(
                      weekData[currDate].peakLoadPrice,
                      weekData[currDate - 1].peakLoadPrice
                    )}
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <h4>Daily Volume (MWh)</h4>
              </td>
              <td style={{ position: "relative" }}>
                <p>
                  {weekData.length > 1
                    ? (
                        Math.round(
                          weekData[currDate - 1].dailyVolumeMwh * 100
                        ) / 100
                      ).toFixed(2)
                    : ""}
                </p>{" "}
                {weekData.length > 1 && currDate - 2 >= 0 && (
                  <span
                    className={`${
                      calculatePercentage(
                        weekData[currDate - 1].dailyVolumeMwh,
                        weekData[currDate - 2].dailyVolumeMwh
                      ).includes("-")
                        ? "red left"
                        : "green left"
                    }`}
                  >
                    {calculatePercentage(
                      weekData[currDate - 1].dailyVolumeMwh,
                      weekData[currDate - 2].dailyVolumeMwh
                    )}
                  </span>
                )}
              </td>
              <td>
                <p>
                  {(
                    Math.round(weekData[currDate].dailyVolumeMwh * 100) / 100
                  ).toFixed(2)}
                </p>
                {weekData.length > 1 && currDate - 1 >= 0 && (
                  <span
                    className={`${
                      calculatePercentage(
                        weekData[currDate].dailyVolumeMwh,
                        weekData[currDate - 1].dailyVolumeMwh
                      ).includes("-")
                        ? "red right"
                        : "green right"
                    }`}
                  >
                    {calculatePercentage(
                      weekData[currDate].dailyVolumeMwh,
                      weekData[currDate - 1].dailyVolumeMwh
                    )}
                  </span>
                )}
              </td>
            </tr>
            <tr id="last-row">
              <td>
                <h3>Monthly Volume (MWh)</h3>
              </td>
              <td colSpan="2">
                <p>
                  {(
                    Math.round(weekData[currDate].monthlyVolumeMwh * 100) / 100
                  ).toFixed(2)}
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  // else {
  //   return <Loader />;
  // }
};

export default DayAhead;
