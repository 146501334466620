import React, { useEffect } from "react";
import LoaderIcon from "../../../../assets/svgs/Loader";
import "./Loader.scss";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import random from "random-words";

const Loader = () => {
  const autoplayOptions = {
    delay: 100,
    rootNode: (emblaRoot) => emblaRoot.parentElement,
    stopOnInteraction: false,
  };
  const [emblaRef] = useEmblaCarousel(
    { loop: true, slidesToScroll: 1, startIndex: 1 },
    [Autoplay(autoplayOptions)]
  );
  useEffect(() => {}, [emblaRef]);

  return (
    <>
      <div className="backdrop"></div>
      <div className="loader-wrapper">
        <div className="loader-container">
          <Splide
            options={{
              type: "loop",
              drag: "free",
              rewind: true,
              autoplay: true,
              perMove: 5,
              perPage: 1,
              gap: "0px",
              arrows: false,
              pagination: false,
              resetProgress: false,
              autoScroll: {
                pauseOnHover: false,
                pauseOnFocus: false,
                speed: 1.5,
              },
            }}
            extensions={{ AutoScroll }}
          >
            <SplideSlide>
              <div className="our-team-card">
                <LoaderIcon />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="our-team-card">
                <LoaderIcon />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="our-team-card">
                <LoaderIcon />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="our-team-card">
                <LoaderIcon />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="our-team-card">
                <LoaderIcon />
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </>
  );
};

export default Loader;
