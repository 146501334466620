import React from "react";
import "./Login.scss";
import { useDispatch } from "react-redux";
import { authActions } from "../../slices/auth";

const Login = () => {
  const dispatch = useDispatch();

  const handleLogin = () => {
    dispatch(authActions.setToken("naja"));
    window.location = "/";
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-group">
          <label htmlFor="email">E-mail</label>
          <input type="text" id="email" />
        </div>
        <div className="login-group">
          <label htmlFor="password">Password</label>
          <input type="password" name="password-name" id="password" />
        </div>
        <button className="btn" onClick={handleLogin}>
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
