import React from "react";
import YelowArrow from "../../../assets/svgs/YelowArrow";
import "./Map.scss";
import { useNavigate } from "react-router";

const Map = () => {
  const navigate = useNavigate();
  return (
    <div className="map-wrapper">
      <div className="map-container">
        <h1 className="primary-heading">What we do</h1>
        <p>
          We buy and sell green energy in <span>Montenegro</span> and
          neighbouring countries.
        </p>
        <button className="btn" onClick={() => navigate("/contact")}>
          Get a quote
        </button>
      </div>
      <YelowArrow />
    </div>
  );
};

export default Map;
