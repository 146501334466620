import React from "react";

const MarketStudies = () => {
  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="50.5" y="26" width="8" height="28" fill="#24DC8F" />
      <rect x="38.5" y="16" width="8" height="38" fill="#24DC8F" />
      <path d="M26.5 40.5L34.5 35.5V54H26.5V40.5Z" fill="#24DC8F" />
      <rect x="14.5" y="42" width="8" height="12" fill="#24DC8F" />
      <circle cx="18.5" cy="22" r="16" fill="#24DC8F" />
    </svg>
  );
};

export default MarketStudies;
