import React from "react";
import WhyMedex1 from "../../../assets/svgs/WhyMedex1";
import WhyMedex2 from "../../../assets/svgs/WhyMedex2";
import WhyMedex3 from "../../../assets/svgs/WhyMedex3";
import WhyMedexLongM from "../../../assets/svgs/WhyMedexLongM";
import "./WhyMedex.scss";

const WhyMedex = () => {
  return (
    <div className="why-medex-wrapper">
      {/* <WhyMedexLongM /> */}
      <div className="why-medex-container">
        <h2 className="primary-heading">why medex?</h2>
        <div className="why-medex-cards">
          <div className="why-medex-card">
            <WhyMedex1 />
            <p>
              We pledge our <span>expertise and </span> <br />
              <span>professionalism</span> to achieve the highest results in
              power generation revenues.
            </p>
          </div>
          <div className="why-medex-card">
            <WhyMedex2 />
            <p>
              <span>Innovation</span> – the driving force behind Medex solutions
              - continues to be one of the values that guide our actions.
            </p>
          </div>
          <div className="why-medex-card">
            <WhyMedex3 />
            <p>
              <span>Playing the </span> <span>leading role </span>
              in transactions of green energy in Montenegro and developing new
              business opportunities.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyMedex;
