import * as api from "../api/getData";

export const getWeeklyData = async () => {
  try {
    const response = await api.getWeeklyData();
    return response;
  } catch (error) {
    
    return error;
  }
};
export const getDataByDate = async (date) => {
  try {
    const response = await api.getDataByDate(date);
    return response;
  } catch (error) {
    
    return error;
  }
};
