import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Close from "../../../../assets/svgs/Close";
import Hamburger from "../../../../assets/svgs/Hamburger";
import Logo from "../../../../assets/svgs/Logo";
import "./Header.scss";

const Header = () => {
  const [headerMobile, setHeaderMobile] = useState(false);

  const controlHeaderMobile = () => {
    setHeaderMobile((prev) => !prev);
    document.getElementById("header-nav").classList.toggle("active-header-nav");
    document
      .getElementById("header-nav-backdrop")
      .classList.toggle("active-header-nav-backdrop");
  };

  const closeMobile = () => {
    setHeaderMobile(false);
    document.getElementById("header-nav").classList.remove("active-header-nav");
    document
      .getElementById("header-nav-backdrop")
      .classList.remove("active-header-nav-backdrop");
  };

  return (
    <div className="header-wrapper">
      <div className="header-container">
        <div className="header-logo">
          <Link onMouseUp={closeMobile} to={"/"}>
            <Logo />
          </Link>
        </div>
        <div className="header-toggle">
          <button onClick={controlHeaderMobile}>
            {headerMobile ? <Close /> : <Hamburger />}
          </button>
        </div>
        <div
          id="header-nav-backdrop"
          className="header-nav-backdrop"
          onClick={controlHeaderMobile}
        ></div>

        <div id="header-nav" className="header-nav">
          <ul>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                onMouseUp={closeMobile}
                to={"/who-we-are"}
                end
              >
                Who we are
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                onMouseUp={closeMobile}
                to={"/what-we-do"}
              >
                What we do
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                onMouseUp={closeMobile}
                to={"/microproduction"}
              >
                Microproduction
              </NavLink>
            </li> */}

            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                onMouseUp={closeMobile}
                to={"/go-green"}
              >
                Go green
              </NavLink>
            </li>
            <li className="mobile-header-contact">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                onMouseUp={closeMobile}
                to={"/contact"}
              >
                Contact us
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="header-contact">
          <Link onMouseUp={closeMobile} to={"/contact"}>
            Contact us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
