import React from "react";
import "./BuildFuture.scss";

const BuildFuture = () => {
  return (
    <div className="build-future-wrapper">
      <div className="build-future-container">
        <div className="text">
          <h1 className="primary-heading">Building the future</h1>
          <p>
            We aim to create a more sustainable future by enabling
            micro-producers, small IPPs, smart energy consumers, and energy
            traders to play essential roles in developing the green power
            network in the <span>Montenegro and Balkans</span>.
          </p>
          <p>
            Here at Medex there is no <span>player too big or too small</span>{" "}
            when it comes to achieving our goals for a brighter and greener
            future. We specialize in all segments of the energy sector and
            strive to be the ideal partner for all producers, traders,
            distributors, and sales companies focusing on green solutions.
          </p>
          <p>
            <span>The collective action of all individuals</span> can bring a
            meaningful impact on the global goal of building a sustainable
            future by bringing renewable energy to every corner of the world.
          </p>
        </div>
        <div className="image"></div>
      </div>
    </div>
  );
};

export default BuildFuture;
