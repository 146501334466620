import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
// import { useScroll } from "framer-motion";

import "./EVCharger.scss";

const EVCharger = ({ images, end, end2 }) => {
  const [counter, setCounter] = useState(1);
  const [scrollTop, setScrollTop] = useState(0);
  const [widthWin, setWidthWin] = useState(0);

  const [oldScrollY, setOldScrollY] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [animationEndBottom, setAnimationEndBottom] = useState(false);
  const [animationEndTop, setAnimationEndTop] = useState(false);
  const [overlap, setOverlap] = useState(false);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  function elementsOverlap(el1, el2) {
    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    return !(
      domRect1.top > domRect2.bottom ||
      domRect1.right < domRect2.left ||
      domRect1.bottom < domRect2.top ||
      domRect1.left > domRect2.right
    );
  }

  useEffect(() => {
    const title = document.querySelector("#animation-title-3");
    const img = document.querySelector(".img");
    function handleResize() {
      setWidthWin(window.innerWidth);
      if (elementsOverlap(title, img)) {
        setOverlap(true);
      } else {
        setOverlap(false);
      }
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [widthWin]);

  // THIS IS WINDOW SCROLLING
  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };
    var someDiv = document.querySelector(".ev-charger-wrapper");
    var distanceToTop = someDiv.getBoundingClientRect().top;

    const contentWrapper = document.querySelector(".ev-charger-container");

    // CONTROLLING THE TITLES
    const first = document.getElementById("animation-title-1");
    const second = document.getElementById("animation-title-2");
    const third = document.getElementById("animation-title-3");

    if (!scrolled) {
      first.style.opacity = 0;
      second.style.opacity = 0;
      third.style.opacity = 0;
    }
    // OVDJE ANIMACIJA POCINJE

    if (distanceToTop < 50 && !animationEndTop) {
      if (!scrolled) setOldScrollY(window.scrollY);
      setScrolled(true);

      contentWrapper.style.position = "fixed";
      contentWrapper.style.top = 0;

      if (
        Math.round((window.scrollY - oldScrollY) / 10) < 174 &&
        document.getElementById(
          `img-${Math.round((window.scrollY - oldScrollY) / 10)}`
        )
      ) {
        setCounter(Math.round((window.scrollY - oldScrollY) / 10));
        if (!overlap) {
          first.style.opacity = (window.scrollY - oldScrollY) / 1000 - 0.3;
          second.style.opacity = (window.scrollY - oldScrollY) / 1000 - 0.5;
          third.style.opacity = (window.scrollY - oldScrollY) / 1000 - 0.7;
        } else {
          first.style.opacity = 0;
          second.style.opacity = 0;
          third.style.opacity = 0;
        }
      }
      if (
        !document.getElementById(
          `img-${Math.round((window.scrollY - oldScrollY) / 10)}`
        )
      ) {
        setCounter(1);
      }
    }

    // OVO PREDSTAVLJA KRAJ ANIMACIJE SA DONJE STRANE

    if (
      oldScrollY !== 0 &&
      Math.round((window.scrollY - oldScrollY) / 10) >= 174
    ) {
      setAnimationEndBottom(true);

      setCounter(174);
    }

    // OVO PREDSTAVLJA KRAJ ANIMACIJE SA GORNJE STRANE
    if (window.scrollY > 200 && end2) {
      setAnimationEndTop(true);

      contentWrapper.style.position = "absolute";
      contentWrapper.style.minHeight = "400px";

      setCounter(1);
    }

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [scrollTop]);

  return (
    <div className="ev-charger-wrapper">
      <div className="ev-charger-container">
        <h1 className="primary-heading">ev charger</h1>
        <div className="ev-charger-content-wrapper">
          <div className="left">
            <p>
              Medex is building Montenegro's EV charger grid, making electric
              mobility accessible to all. With our innovative approach to
              electric energy, we ensure seamless processes.
            </p>
            <p>
              Experience the power of genuine green energy with us - every MWh
              generated comes from 100% renewable sources.
            </p>
            <h5 ref={ref1} id="animation-title-1" className="animation-title">
              Easy operation
            </h5>
            <h5 ref={ref2} id="animation-title-2" className="animation-title">
              guarantee of origin
            </h5>
            <h5 ref={ref3} id="animation-title-3" className="animation-title">
              fast charging system
            </h5>
          </div>
          <div className="right" id="car-animation">
            {images.map((src, i) => (
              <img
                key={i}
                loading="lazy"
                src={src}
                id={`img-${i}`}
                className="img"
                style={{
                  position: "fixed",
                  top: "0",
                  opacity: i === counter ? "1" : "0",
                }}
                alt="src"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EVCharger;

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
