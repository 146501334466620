import React, { useState } from "react";
import Counter from "../../components/Landing/Counter";
import LandingStart from "../../components/Landing/LandingStart";
import Statistics from "../../components/Landing/Statistics/Statistics";
import Video from "../../components/Landing/Video";
import Contact from "../../components/UI/Contact/Contact";
import "./Landing.scss";
import ContactModal from "../../components/UI/ContactModal/ContactModal";
import Object from "../../components/Landing/Object";

const Landing = () => {
  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  return (
    <>
      {modal && <ContactModal close={() => setModal(false)} />}
      <div className="landing-page-wrapper">
        <LandingStart />
        <Counter />
        <Statistics />
        <Video />
        <Object />
        <Contact open={openModal} />
      </div>
    </>
  );
};

export default Landing;
