import React from "react";

const Balancing = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="17" r="16" fill="#24DC8F" />
      <rect x="14" y="37" width="32" height="10" fill="#24DC8F" />
      <rect x="14" y="50" width="32" height="10" fill="#24DC8F" />
    </svg>
  );
};

export default Balancing;
