import { TextField } from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import "./ChartWrap.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Arrow from "../../../../assets/svgs/Arrow";
import Chart from "./Chart";
import BarChart from "./BarChart";
import LineChartComp from "./LineChart";
import Loader from "../../UI/Loader/Loader";
import fulldata from "../../../services/testData.json";
import { getDataByDate, getWeeklyData } from "../../../services/getData";

const ChartWrap = () => {
  const [today, setToday] = useState(new Date());
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const [value, setValue] = React.useState(dayjs(today));
  const [view, setView] = useState("price");
  const [dayData, setDayData] = useState();
  const [isItTomorrow, setIsItTommorow] = useState(false);

  // useEffect(() => {
  //
  // }, [value]);

  useEffect(() => {
    if (today.getUTCHours() >= 14) {
      // setToday(dayjs(today.setDate(today.getDate() + 1)));
      setIsItTommorow(true);
      getDataByDate(dayjs(tomorrow).toISOString()).then((data) => {
        setDayData(data.data);
      });
      setValue(dayjs(tomorrow));
    } else {
      getDataByDate(dayjs(today).toISOString()).then((data) => {
        setDayData(data.data.data);
      });
      setIsItTommorow(false);
      setValue(dayjs(today));
    }
  }, []);

  useEffect(() => {
    getDataByDate(dayjs(value).toISOString()).then((data) => {
      setDayData(data.data.data);
    });
  }, [value]);

  const handleActive = (e) => {
    var slides = document.getElementsByClassName("chart-choices-btn-item");
    for (var i = 0; i < slides.length; i++) {
      slides[i].classList.remove("active-btn");
    }
    e.target.classList.add("active-btn");
    setView(e.target.value);
  };

  return (
    <div className="chart-wrapper">
      {/* <Loader /> */}
      <div className="chart-container">
        {view === "price" && <h3>Prices (EUR/MWh)</h3>}
        {view === "volume" && <h3>Volume (mw)</h3>}
        {view === "all" && <h3>Prices & Volume </h3>}
        <div className="chart-choices">
          <div className="chart-choices-btn">
            <button
              onClick={handleActive}
              className={`chart-choices-btn-item ${
                view === "price" && "active-btn"
              }`}
              value={"price"}
            >
              Price (EUR/MWh)
            </button>
            <button
              onClick={handleActive}
              className={`chart-choices-btn-item ${
                view === "volume" && "active-btn"
              }`}
              value={"volume"}
            >
              Volume (MW)
            </button>
            <button
              onClick={handleActive}
              className={`chart-choices-btn-item ${
                view === "all" && "active-btn"
              }`}
              value={"all"}
            >
              View All
            </button>
          </div>
          <div className="chart-choices-date">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="date-picker"
                minDate={dayjs("2023-04-06")}
                maxDate={!isItTomorrow ? dayjs(today) : dayjs(tomorrow)}
                inputFormat="MMM DD, YYYY"
                value={value}
                showToolbar={false}
                components={{
                  OpenPickerIcon: Arrow,
                }}
                acceptRegex={true}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        {view === "price" && dayData && <LineChartComp data={dayData} />}
        {view === "volume" && dayData && <BarChart data={dayData} />}
        {view === "all" && dayData && <Chart data={dayData} />}
      </div>
    </div>
  );
};

export default ChartWrap;
