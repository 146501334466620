import React from "react";
import "./ServingCommunity.scss";

const ServingCommunity = () => {
  return (
    <div className="go-green-community-wrapper">
      <div className="go-green-community-container">
        <div className="text">
          <p>
            We understand that this global movement to revolutionize how we look
            at energy sources takes the collective approach.
          </p>
          <p>
            Medex recognizes the importance of businesses and the private sector
            contributing to the realization of{" "}
            <span>Montenegro’s NSOR 2030</span>. That’s why we are committed to
            be that key factor in Montenegro’s work towards shifting to green
            power solutions.
          </p>
          <p>
            Our work in the following years will{" "}
            <span>ensure the dialogue and action</span> around sustainability
            issues is at the head of our company’s mission.
          </p>
        </div>
        <div className="image">
          <h1 className="primary-heading">serving the community</h1>
        </div>
      </div>
    </div>
  );
};

export default ServingCommunity;
