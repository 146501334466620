import React, { Suspense, useState } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Html,
  OrbitControls,
  PerformanceMonitor,
  PresentationControls,
  useGLTF,
  useProgress,
} from "@react-three/drei";
import { Link } from "react-router-dom";
import LinkArrow from "../../../assets/svgs/LinkArrow";
import "./Object.scss";

const Object = () => {
  return (
    <div className="landing-page-object-wrapper">
      <div className="glow-bg">
        <div className="landing-page-object-container">
          <h1 className="primary-heading">go green with medex </h1>
          <div className="object-text-wrapper">
            <div className="left">
              <ObjectModel />
            </div>
            <div className="right">
              <div className="wrap-text">
                <p>
                  Medex is more than an electricity trading platform. We're
                  passionate about creating a greener world. That's why we've
                  launched our Go Green Initiative, bringing electric vehicle
                  chargers to Montenegro.
                </p>
                <p>
                  Embrace clean energy and be part of the movement. Discover our
                  customized solutions and join our global network of partners
                  committed to a unique and sustainable experience.
                </p>
              </div>
              <span className="big">100 EV chargers</span>
              <span className="small">by the year 2030</span>
              <span className="big">10% Carbon emissions</span>
              <span className="small">reduction</span>
              <Link to={"/go-green"}>
                Visit our Go Green page to learn more <LinkArrow />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Object;

function Loader() {
  const { progress } = useProgress();
  return <Html center>{progress} % loaded</Html>;
}

function Model({ url }) {
  const { scene } = useGLTF("/charger_glb.glb");
  return <primitive object={scene} />;
}

const ObjectModel = () => {
  const [dpr, setDpr] = useState(1.5);

  return (
    <div
      id="canvas-container"
      style={{ backgroundColor: "transparent", color: "#000" }}
    >
      <Suspense fallback={<span>loading...</span>}>
        <Canvas
          dpr={dpr}
          frameloop="demand"
          style={{
            backgroundColor: "transparent",
          }}
        >
          <PerformanceMonitor
            onChange={({ factor }) => setDpr(Math.round(0.5 + 1.5 * factor, 1))}
          />

          <ambientLight intensity={0.7} />
          <Suspense fallback={<Loader />}>
            <PresentationControls enabled={false}>
              <Model />
            </PresentationControls>
          </Suspense>
          <OrbitControls
            autoRotate={true}
            enableZoom={false}
            enablePan={false}
            autoRotateSpeed={4.0}
            minPolarAngle={Math.PI / 2}
            maxPolarAngle={Math.PI / 2}
            onClick={(e) => true}
          />
        </Canvas>
      </Suspense>
    </div>
  );
};
