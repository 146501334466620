import React, { useEffect, useState } from "react";
import "./LineChart.scss";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useLocation } from "react-router";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          {`${label} : `}
          <span
            style={{ fontWeight: "900" }}
          >{`${payload[0].value}`}</span>{" "}
          EUR/MWh
        </p>

        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        {/* <p className="desc">Anything you want can be displayed here.</p> */}
        {/* <Svg /> */}
      </div>
    );
  }

  return null;
};

const LineChartComp = (props) => {
  const [posData, setposData] = useState({});
  const [widthWin, setWidthWin] = useState(0);
  const { pathname } = useLocation();
  const data = props.data;

  useEffect(() => {
    if (pathname.includes("platform")) return;
    function handleResize() {
      setWidthWin(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [pathname, widthWin]);

  return (
    <div
      style={{
        width: "calc(100vw - 16px) !important",
        height: "calc(100vh - 560px)",
        paddingTop: "70px",
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid
            strokeDasharray="0 0"
            stroke="#E6EAE8"
            vertical={false}
            //   horizontalPoints={[100, 300, 500, 700]}
            horizontal={true}
          />
          <XAxis
            dataKey="hours"
            //   interval={4}
            ticks={["H1", "H6", "H12", "H18", "H24"]}
            tickLine={false}
            axisLine={{ stroke: "#f5f5f5" }}
            stroke="#24DC8F"
          />
          <YAxis
            stroke="#24DC8F"
            axisLine={{ stroke: "#f5f5f5" }}
            //   unit="K"
            // domain={[0, 10, "dataMax + 100"]}
            tickCount={4}
            tickMargin={15}
            // domain={[5, "dataMax + 10"]}
            // tickCount={5}
            orientation="right"
          />
          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{ outline: "none" }}
            position={{ x: posData.x - 55, y: posData.y - 50 }}
            cursor={{ fill: "transparent" }}
          />
          {/* <Legend /> */}

          <Line
            dot={false}
            strokeWidth={widthWin < 370 ? 4 : 6}
            strokeLinecap="round"
            type="monotone"
            dataKey="value"
            stroke="#24DC8F"
            // yAxisId="right"
            legendType="rect"
            name="value"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComp;
