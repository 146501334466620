import React from "react";

const BindingOffers = () => {
  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.69141 14L49.6914 14L56.6914 21L49.6914 28H4.69141V14Z"
        fill="#24DC8F"
      />
      <path
        d="M56.6914 33L11.6914 33L4.69141 40L11.6914 47H56.6914V33Z"
        fill="#24DC8F"
      />
    </svg>
  );
};

export default BindingOffers;
