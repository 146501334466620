import React from "react";
import IconLink from "../UI/IconLink/IconLink";
import "./Link.scss";

const GoGreenLink = () => {
  return (
    <div className="go-green-link-wrapper">
      <div className="go-green-link-container">
        <h2>
          Join us in our mission to revolutionize how we <br /> make and use
          electric energy in Montenegro.
        </h2>
        <IconLink content={`Join us \n in our mission`} link="/contact" />
      </div>
    </div>
  );
};

export default GoGreenLink;
