import React from "react";

const SingleGreenLine = () => {
  return (
    <svg
      width="211"
      height="146"
      viewBox="0 0 211 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1742_8385"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="211"
        height="146"
      >
        <rect x="0.5" width="210.199" height="146" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1742_8385)">
        <path
          d="M57.9113 146L210.704 0H154.883L3.60645 146H57.9113Z"
          fill="#24DC8F"
        />
      </g>
    </svg>
  );
};

export default SingleGreenLine;
