import React from "react";
import BlueM from "../../../assets/svgs/BlueM";
import SingleGreenLine from "../../../assets/svgs/SingleGreenLine";
import "./WhoWeAreText.scss";
import { useNavigate } from "react-router";

const WhoWeAreText = () => {
  const navigate = useNavigate();
  return (
    <div className="who-we-are-text-wrapper">
      <div className="who-we-are-text-container">
        <div className="text">
          <h1 className="primary-heading">Who we are</h1>
          <p>
            <span>A harmonic team</span> of experienced businessmen, young and
            tremendously successful IT professionals, and independent energy
            experts came together back in 2020 and decided to extend their
            Forbes noted portfolio from IT to smart energy solutions.
          </p>
          <p>
            <span>In the search for optimal solutions</span> that would answer
            our own needs and plans, we have developed a set of products that we
            are incredibly proud to share with you.
          </p>
          <button className="btn" onClick={() => navigate("/contact")}>
            Get in touch
          </button>
        </div>
        <div className="image">
          <SingleGreenLine />
          <BlueM />
        </div>
      </div>
    </div>
  );
};

export default WhoWeAreText;
