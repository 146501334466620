import React from "react";

const Hamburger = () => {
  return (
    <svg
      width="42"
      height="26"
      viewBox="0 0 42 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M41 1H1" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M41 13H1" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M41 25H1" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default Hamburger;
