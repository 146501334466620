import { Route, Routes } from "react-router";
import "./App.scss";
import Footer from "./app/components/UI/Footer/Footer";
import Header from "./app/components/UI/Header/Header";
import Protect from "./app/components/UI/Protect/Protect";
import Landing from "./app/pages/Landing/Landing";
import Login from "./app/pages/Login/Login";
import Solar from "./app/pages/Solar/Solar";
import WhatWeDo from "./app/pages/WhatWeDo/WhatWeDo";
import { useSelector } from "react-redux";
import GoGreen from "./app/pages/GoGreen/GoGreen";
import Contact from "./app/pages/Contact/Contact";
import Loader from "./app/components/UI/Loader/Loader";
import NotFound from "./app/pages/NotFound/NotFound";
import WhoWeAre from "./app/pages/WhoWeAre/WhoWeAre";
import { Fragment, useEffect, useState } from "react";
import Page from "./app/pages/ContactCards/Page";
import Data from "./app/pages/ContactCards/Data";

function App() {
  const authStore = useSelector((store) => store.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);

  const preloadList = Array.from(
    { length: 175 },
    (_, i) => `/car-animation/${i + 1}.webp`
  );

  const { imagesPreloaded } = useImagePreloader(preloadList);

  return (
    <div className="App">
      <Fragment>
        {/* {!imagesPreloaded && <Loader />} */}
        <Header />
        <Routes>
          <Route element={<Login />} path="/sign-in" />
          <Route element={<Landing />} path="/" />
          <Route element={<WhatWeDo />} path="/what-we-do" />
          <Route element={<WhoWeAre />} path="/who-we-are" />
          {/* <Route element={<Solar />} path="/microproduction" /> */}
          <Route element={<GoGreen images={preloadList} />} path="/go-green" />

          <Route element={<Contact />} path="/contact" />
          <Route element={<Page item={Data[0]} />} path="/contact/david" />
          <Route element={<Page item={Data[1]} />} path="/contact/dragan" />
          <Route element={<Page item={Data[2]} />} path="/contact/ljubo" />
          <Route element={<Page item={Data[3]} />} path="/contact/drazen" />
          <Route element={<NotFound />} path="*" />
        </Routes>
        <Footer />
      </Fragment>
    </div>
  );
}

export default App;

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    window[img] = img;
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

export function useImagePreloader(imageList) {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      const imagesPromiseList = [];
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i));
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded };
}
