import React from "react";
import { Link } from "react-router-dom";
import "./Page.scss";
import {
  Telephone,
  Envelope,
  Linkedin,
  Whatsapp,
  Globe,
  Plus,
} from "react-bootstrap-icons";
import Logo from "../../../assets/svgs/Logo";
import { Helmet } from "react-helmet";

const Page = (props) => {
  return (
    <div className="contact-card-page-wrapper">
      <Helmet>
        <title>Medex</title>
        <meta name="theme-color" content="#00CC76" />
      </Helmet>

      <div className="card-wrapper">
        <div className="card-container">
          <div className="content">
            {" "}
            <Link to={"/"}>
              <Logo />
            </Link>
            <h3>{props.item.name}</h3>
            <span>{props.item.position}</span>
            <div className="add-contacts">
              <a type="text/vcf" download={props.item.file} href={props.item.file}>
                <Plus size={22} color="#fff" /> Add to contacts
              </a>{" "}
            </div>
            <div className="contact-data">
              <div className="contact-item">
                <div className="icon">
                  <Telephone size={22} color="#545454" />
                </div>
                <div className="info">
                  <span>Telephone</span>
                  <a href={`tel:${props.item.telephone}`}>
                    {props.item.telephone}
                  </a>
                </div>
              </div>
              <div className="contact-item">
                <div className="icon">
                  <Envelope size={22} color="#545454" />
                </div>
                <div className="info">
                  <span>Email address</span>
                  <a href={`mailto: ${props.item.mail}`}>{props.item.mail}</a>
                </div>
              </div>
              {props.item.linkedin !== "" && (
                <div className="contact-item">
                  <div className="icon">
                    <Linkedin size={22} color="#545454" />
                  </div>
                  <div className="info">
                    <span>LinkedIn</span>
                    <a target="_blank" href={props.item.linkedin}>
                      {props.item.linkedin}
                    </a>
                  </div>
                </div>
              )}
              <div className="contact-item">
                <div className="icon">
                  <Whatsapp size={22} color="#545454" />
                </div>
                <div className="info">
                  <span>Whatsapp</span>
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=${props.item.wa}`}
                  >
                    {props.item.telephone}
                  </a>
                </div>
              </div>
              <div className="contact-item">
                <div className="icon">
                  <Globe size={22} color="#545454" />
                </div>
                <div className="info">
                  <span>Website</span>
                  <Link to={"/"}>{props.item.web}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
