import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Counter.scss";
import CountUp, { useCou } from "react-countup";

const Counter = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const [rerender, setRerender] = useState(false);

  const isInViewport1 = useIsInViewport(ref1);

  if (isInViewport1) {
    if (!rerender) setRerender(true);
  }

  useEffect(() => {}, [rerender]);

  return (
    <div className="landing-page-counter-wrapper">
      <div className="landing-page-counter-container">
        <section ref={ref1}>
          {rerender && (
            <p className="primary">
              <CountUp start={0} end={23} delay={0} duration={2}>
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
              <span className="absolute">MW</span>
            </p>
          )}
          <p className="secondary">Production</p>
        </section>
        <section ref={ref2}>
          {rerender && (
            <p>
              <CountUp start={0} end={12} delay={0} duration={2}>
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            </p>
          )}
          <p className="secondary">Power plants</p>
        </section>
        <section ref={ref3}>
          {rerender && (
            <p>
              <CountUp start={0} end={4} delay={0} duration={2}>
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            </p>
          )}
          <p className="secondary">Markets</p>
        </section>
      </div>
    </div>
  );
};

export default Counter;

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
