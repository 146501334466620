import React from "react";

const YelowArrow = () => {
  return (
    <svg
      width="291"
      height="216"
      viewBox="0 0 291 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1096_3171"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="612"
        height="388"
      >
        <path
          d="M64.4543 215.842L309.118 33.9836V156.476H373.572L491.206 69.9325V6.67241L358.091 107.013V-1.00003L273.216 -0.300995L272.467 0.248199H272.052L114.258 117.548L114.275 117.831L48.9726 166.379V-0.300995H-3.05176e-05V215.842H64.4543Z"
          fill="black"
        />
        <path
          d="M163.963 324.088V386.965H500.718V339.183H212.121L284.158 239.641L245.695 211.348L163.963 324.088Z"
          fill="black"
        />
        <path
          d="M0.132874 171.605V168.06H290.809V230.937L212.087 339.083L173.624 310.79L242.668 215.842H32.2935L0.132874 171.605Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_1096_3171)">
        <rect
          width="291"
          height="216"
          transform="matrix(1 0 0 -1 0 216)"
          fill="#FDF451"
        />
      </g>
    </svg>
  );
};

export default YelowArrow;
