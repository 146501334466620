import React from "react";

const Aggregating = () => {
  return (
    <svg
      width="58"
      height="57"
      viewBox="0 0 58 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="33" r="16" fill="#24DC8F" />
      <circle cx="28" cy="6" r="6" fill="#24DC8F" />
      <circle cx="52" cy="51" r="6" fill="#24DC8F" />
      <circle cx="6" cy="51" r="6" fill="#24DC8F" />
    </svg>
  );
};

export default Aggregating;
