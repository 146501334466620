import React from "react";
import "./LandingStart.scss";
import { useDispatch } from "react-redux";
import { authActions } from "../../slices/auth";
import { useNavigate } from "react-router";
import poster from "../../../assets/windmills-preview.png";

const LandingStart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="landing-page-start-wrapper">
      <video
        src="videos/windmills.mp4"
        poster={poster}
        autoPlay
        loop
        muted="muted"
        playsInline
      ></video>
      <div className="landing-page-start-container">
        <h1 className="primary-heading">DISCOVER YOUR POWERS</h1>
        <p>
          Sell or buy renewable energy in Montenegro through the platform that
          guarantees liquidity.
        </p>
        <button
          className="btn"
          onClick={() => {
            // dispatch(authActions.resetToken());
            // window.location = "/sign-in";
            navigate("/contact");
          }}
        >
          Get consultation
        </button>
      </div>
    </div>
  );
};

export default LandingStart;
