import React from "react";
import FixedPrice from "../../../assets/svgs/FixedPrice";
import Pin from "../../../assets/svgs/Pin";
import Star from "../../../assets/svgs/Star";
import "./YouGet.scss";

const YouGet = () => {
  return (
    <div className="you-get-wrapper">
      <div className="you-get-container">
        <h1 className="primary-heading">You'll get:</h1>
        <div className="items">
          <div className="item">
            <Pin />
            <p>
              <span>Paid at spot</span> market price for any single kWh you
              deliver
            </p>
          </div>
          <div className="item">
            <Star />
            <p>
              <span>Special price</span> equal to the spot market price for any
              single kWh you spent
            </p>
          </div>
          <div className="item">
            <FixedPrice />
            <p>
              We only take a fixed fee of <span>5.4 €/month</span> for providing
              you an opportunity of free market access.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouGet;
