import david from "../../../assets/contact/david.vcf";
import ljubo from "../../../assets/contact/ljubo.vcf";
import dragan from "../../../assets/contact/dragan.vcf";
import drazen from "../../../assets/contact/drazen.vcf";

const data = [
  {
    name: "David Koroev",
    position: "CMO & Partner",
    telephone: "+38267677778",
    mail: "david@medex.me",
    linkedin: "",
    wa: "38267677778",
    web: "medex.me",
    file: david,
  },
  {
    name: "Dragan Knezevic",
    position: "CEO & Partner",
    telephone: "+38267204667",
    mail: "dragan@medex.me",
    linkedin: "https://linkedin.com/in/dragan-knezevic-b7a88994",
    wa: "38267204667",
    web: "medex.me",
    file: dragan,
  },
  {
    name: "Ljubo Knezevic",
    position: "CEO advisor",
    telephone: "+38267506607",
    mail: "ljubo@medex.me",
    linkedin: "https://linkedin.com/in/ljubo-knezevic",
    wa: "38267506607",
    web: "medex.me",
    file: ljubo,
  },
  {
    name: "Drazen Vlaovic",
    position: "COO",
    telephone: "+38268303529",
    mail: "drazen@medex.me",
    linkedin: "https://linkedin.com/in/drazen-vlaovic-2b522653",
    wa: "38268303529",
    web: "medex.me",
    file: drazen,
  },
];

export default data;
