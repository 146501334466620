import React from "react";

const GreenEnergy = () => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7928 11.5871C3.03656 23.3434 6.11572 42.4526 7.11579 54.4526C21.1159 55.9526 38.5541 59.39 50.6159 47.3282C62.6777 35.2663 60.6159 14.2237 60.6159 1.22367C47.3199 0.980485 27.1671 -0.787297 14.7928 11.5871Z"
        fill="#24DC8F"
        stroke="#24DC8F"
        strokeWidth="2"
      />
      <path d="M10 52.376L3 59.376" stroke="#24DC8F" strokeWidth="6" />
      <rect
        x="23.8447"
        y="24.8135"
        width="20.2567"
        height="7.55603"
        fill="#121214"
      />
      <path
        d="M44.0596 16.9579L35.2317 24.8186L23.8974 24.8186L44.0596 7.0471L44.0596 16.9579Z"
        fill="#121214"
      />
      <path
        d="M23.9385 40.2351L32.7707 32.3688L44.1007 32.3687L23.9385 50.1402L23.9385 40.2351Z"
        fill="#121214"
      />
    </svg>
  );
};

export default GreenEnergy;
