import React from "react";

const Play = () => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 55C42.6878 55 55 42.6878 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5C0 42.6878 12.3122 55 27.5 55ZM37.8994 29.4822C38.982 28.6828 38.982 27.0639 37.8994 26.2645L25.5079 17.1137C24.1876 16.1387 22.3198 17.0813 22.3198 18.7226L22.3198 37.0242C22.3198 38.6655 24.1876 39.6081 25.5079 38.6331L37.8994 29.4822Z"
        fill="#24DC8F"
      />
    </svg>
  );
};

export default Play;
