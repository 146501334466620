import React, { useEffect, useState } from "react";
import "./OurTeam.scss";
import david from "../../../assets/team/David.png";
import dragan from "../../../assets/team/Dragan.png";
import drazen from "../../../assets/team/Drazen.png";
import dusan from "../../../assets/team/Dusan.png";
import sasa from "../../../assets/team/Sasa.png";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import random from "random-words";
import { Carousel } from "react-bootstrap";
import { CarouselItem } from "react-bootstrap";
import OurTeamLinkedin from "../../../assets/svgs/OurTeamLinkedin";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";

// new Splide(".splide").mount({ AutoScroll });

const OurTeam = () => {
  // debugging
  //jos debugging
  const autoplayOptions = {
    delay: 3000,
    rootNode: (emblaRoot) => emblaRoot.parentElement,
    stopOnInteraction: false,
  };
  const [emblaRef] = useEmblaCarousel(
    { loop: true, slidesToScroll: 1, startIndex: 1 },
    [Autoplay(autoplayOptions)]
  );

  useEffect(() => {}, [emblaRef]);

  const [widthWin, setWidthWin] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidthWin(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [widthWin]);

  return (
    <div className="our-team-wrapper">
      <div className="our-team-container">
        <h1 className="primary-heading">our team</h1>
        <p>
          Our passion and strength stems from our people, individually and as a
          compact team. We blend our strengths together, combining our business
          experience, expertise, and creative minds to bring the best ideas to
          fruition and drive results for our partners.
        </p>
        <Splide
          options={{
            type: "loop",
            drag: "free",
            rewind: true,
            autoplay: true,
            perMove: 5,
            perPage:
              widthWin > 1460
                ? 3.5
                : widthWin > 1070
                ? 2.5
                : widthWin > 673
                ? 2
                : 1,
            gap: "20px",
            arrows: false,
            pagination: false,
            resetProgress: false,
            autoScroll: {
              pauseOnHover: false,
              pauseOnFocus: false,
              speed: 1.5,
            },
          }}
          extensions={{ AutoScroll }}
        >
          <SplideSlide>
            <div className="our-team-card">
              <div className="our-team-card-img">
                <img src={dragan} alt="team" />
              </div>
              <div className="name-btn">
                <h5>Dragan Knezevic</h5>
                <a
                  href="https://www.linkedin.com/in/dragan-knezevic-b7a88994"
                  className="our-team-btn"
                  target="_blank"
                >
                  <OurTeamLinkedin />
                </a>
              </div>
              <span>CEO & Partner</span>
              <p>
                Manages and directs Medex toward its primary goals and
                objectives. Former Head of revenue and regulatory department at
                national DSO.
              </p>
            </div>{" "}
          </SplideSlide>
          <SplideSlide>
            <div className="our-team-card">
              <div className="our-team-card-img">
                <img src={drazen} alt="team" />
              </div>
              <div className="name-btn">
                <h5>Drazen Vlaovic</h5>
                {/* <a
                  href="https://www.linkedin.com/in/drazen-vlaovic-2b522653"
                  target="_blank"
                  className="our-team-btn"
                >
                  <OurTeamLinkedin />
                </a> */}
              </div>
              <span>COO</span>
              <p>
                Implements company strategies into daily operations to meet
                objectives. Former Head of research at Montenegrin Investment
                Promotion Agency and project coordinator at Savana group.
              </p>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="our-team-card">
              <div className="our-team-card-img">
                <img src={david} alt="team" />
              </div>
              <div className="name-btn">
                <h5>David Koroev</h5>
                {/* <a
                  href="https://www.linkedin.com/in/koroev"
                  target="_blank"
                  className="our-team-btn"
                >
                  <OurTeamLinkedin />
                </a> */}
              </div>
              <span>CMO & Partner</span>
              <p>
                Establishes and oversees development and execution of Medex’s
                marketing and advertising initiatives. Former CMO of several IT
                companies and marketing agencies.
              </p>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="our-team-card">
              <div className="our-team-card-img">
                <img src={dusan} alt="team" />
              </div>
              <div className="name-btn">
                <h5>Dusan Bozovic</h5>
                {/* <a
                  href="https://www.linkedin.com/in/du%C5%A1an-bo%C5%BEovi%C4%87-44362310a"
                  target="_blank"
                  className="our-team-btn"
                >
                  <OurTeamLinkedin />
                </a> */}
              </div>
              <span>CFO</span>
              <p>
                Manages and monitor the financial actions of the company, tracks
                cash flow, analyze strengths and weaknesses to propose
                corrective action plans when necessary and prepares forecasts.
                Former Head accountant at China Road and Bridge Corporation Ltd.
              </p>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="our-team-card">
              <div className="our-team-card-img">
                <img src={sasa} alt="team" />
              </div>
              <div className="name-btn">
                <h5>Sasa Scepanovic</h5>
                {/* <a
                  href="https://www.linkedin.com/in/sscepanovic"
                  target="_blank"
                  className="our-team-btn"
                >
                  <OurTeamLinkedin />
                </a> */}
              </div>
              <span>CIO & Partner</span>
              <p>
                Establishes and oversees information technology initiatives and
                strategy required to support the Medex’s unique objectives and
                goals. Former CTO of several IT companies.
              </p>
            </div>
          </SplideSlide>
        </Splide>

        {/* <Carousel controls={false} indicators={true} interval={2000}>
          <CarouselItem>
          <div className="our-team-card-img">
              <img src={dragan} alt="team" />
            </div>
            <div className="name-btn">
              <h5>Dragan Knezevic</h5>
              <a href="/" className="our-team-btn">
                <OurTeamLinkedin />
              </a>
            </div>
            <span>CEO & Partner</span>
            <p>
              Manages and directs Medex toward its primary goals and objectives.
              Former Head of revenue and regulatory department at national DSO.
            </p>
          </CarouselItem>
          <CarouselItem>
          <div className="our-team-card-img">
              <img src={drazen} alt="team" />
            </div>
            <div className="name-btn">
              <h5>Drazen Vlaovic</h5>
              <a href="/" className="our-team-btn">
                <OurTeamLinkedin />
              </a>
            </div>
            <span>COO</span>
            <p>
              Implements company strategies into daily operations to meet
              objectives. Former Head of research at Montenegrin Investment
              Promotion Agency and project coordinator at Savana group.
            </p>
          </CarouselItem>
          <CarouselItem>
          <div className="our-team-card-img">
              <img src={david} alt="team" />
            </div>
            <div className="name-btn">
              <h5>David Koroev</h5>
              <a href="/" className="our-team-btn">
                <OurTeamLinkedin />
              </a>
            </div>
            <span>CMO & Partner</span>
            <p>
              Establishes and oversees development and execution of Medex’s
              marketing and advertising initiatives. Former CMO of several IT
              companies and marketing agencies.
            </p>
          </CarouselItem>
          <CarouselItem>
          <div className="our-team-card-img">
              <img src={dusan} alt="team" />
            </div>
            <div className="name-btn">
              <h5>Dusan Bozovic</h5>
              <a href="/" className="our-team-btn">
                <OurTeamLinkedin />
              </a>
            </div>
            <span>CFO</span>
            <p>
              Manages and monitor the financial actions of the company, tracks
              cash flow, analyze strengths and weaknesses to propose corrective
              action plans when necessary and prepares forecasts. Former Head
              accountant at China Road and Bridge Corporation Ltd.
            </p>
          </CarouselItem>
          <CarouselItem>
          <div className="our-team-card-img">
              <img src={sasa} alt="team" />
            </div>
            <div className="name-btn">
              <h5>Sasa Scepanovic</h5>
              <a href="/" className="our-team-btn">
                <OurTeamLinkedin />
              </a>
            </div>
            <span>CIO & Partner</span>
            <p>
              Establishes and oversees information technology initiatives and
              strategy required to support the Medex’s unique objectives and
              goals. Former CTO of several IT companies.
            </p>
          </CarouselItem>
        </Carousel> */}
        {/* <div className="our-team-card-wrapper">
          <div className="our-team-card">
            <div className="our-team-card-img">
              <img src={dragan} alt="team" />
            </div>
            <div className="name-btn">
              <h5>Dragan Knezevic</h5>
              <a href="/" className="our-team-btn">
                <OurTeamLinkedin />
              </a>
            </div>
            <span>CEO & Partner</span>
            <p>
              Manages and directs Medex toward its primary goals and objectives.
              Former Head of revenue and regulatory department at national DSO.
            </p>
          </div>
          <div className="our-team-card">
            <div className="our-team-card-img">
              <img src={drazen} alt="team" />
            </div>
            <div className="name-btn">
              <h5>Drazen Vlaovic</h5>
              <a href="/" className="our-team-btn">
                <OurTeamLinkedin />
              </a>
            </div>
            <span>COO</span>
            <p>
              Implements company strategies into daily operations to meet
              objectives. Former Head of research at Montenegrin Investment
              Promotion Agency and project coordinator at Savana group.
            </p>
          </div>
          <div className="our-team-card">
            <div className="our-team-card-img">
              <img src={david} alt="team" />
            </div>
            <div className="name-btn">
              <h5>David Koroev</h5>
              <a href="/" className="our-team-btn">
                <OurTeamLinkedin />
              </a>
            </div>
            <span>CMO & Partner</span>
            <p>
              Establishes and oversees development and execution of Medex’s
              marketing and advertising initiatives. Former CMO of several IT
              companies and marketing agencies.
            </p>
          </div>

          <div className="our-team-card">
            <div className="our-team-card-img">
              <img src={dusan} alt="team" />
            </div>
            <div className="name-btn">
              <h5>Dusan Bozovic</h5>
              <a href="/" className="our-team-btn">
                <OurTeamLinkedin />
              </a>
            </div>
            <span>CFO</span>
            <p>
              Manages and monitor the financial actions of the company, tracks
              cash flow, analyze strengths and weaknesses to propose corrective
              action plans when necessary and prepares forecasts. Former Head
              accountant at China Road and Bridge Corporation Ltd.
            </p>
          </div>
          <div className="our-team-card">
            <div className="our-team-card-img">
              <img src={sasa} alt="team" />
            </div>
            <div className="name-btn">
              <h5>Sasa Scepanovic</h5>
              <a href="/" className="our-team-btn">
                <OurTeamLinkedin />
              </a>
            </div>
            <span>CIO & Partner</span>
            <p>
              Establishes and oversees information technology initiatives and
              strategy required to support the Medex’s unique objectives and
              goals. Former CTO of several IT companies.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OurTeam;
