import React from "react";
import "./Pledge.scss";
import car from "../../../assets/backgrounds/car.png";
import GoGreenPledge from "../../../assets/svgs/GoGreenPledge";

const Pledge = () => {
  return (
    <div className="go-green-pledge-wrapper">
      <div className="go-green-pledge-container">
        <div className="text">
          <p>
            At Medex, we lead the charge towards a sustainable revolution. With
            unwavering commitment, we're at the forefront of Montenegro's{" "}
            <span>green energy transition</span>, shaping a brighter tomorrow.
          </p>
          <p>
            Experience innovation as we introduce our{" "}
            <span>high-powered 130kW</span> DC fast charging system for electric
            vehicles (EV). From fuel retail locations to workplaces, we redefine
            eco-conscious journeys.
          </p>
          <p>
            Together, let's build a future that inspires, preserves, and
            empowers generations to come.
          </p>
        </div>
        <div className="image">
          <h1 className="primary-heading">our pledge</h1>
        </div>
      </div>
    </div>
  );
};

export default Pledge;
