import React from "react";

const Pin = () => {
  return (
    <svg
      width="38"
      height="51"
      viewBox="0 0 38 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38 18.8249C38 29.1802 23.5238 50.0749 19 50.0749C14.4762 50.0749 0 29.1802 0 18.8249C0 8.46955 8.50659 0.0748901 19 0.0748901C29.4934 0.0748901 38 8.46955 38 18.8249Z"
        fill="#24DC8F"
      />
    </svg>
  );
};

export default Pin;
