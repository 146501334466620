import React from "react";
import "./ContactModal.scss";
import icon from "../../../../assets/icons/TickIcon.png";
import Close from "../../../../assets/svgs/Close";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ContactModal = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="backdrop-contact-modal"></div>
      <div className="contact-modal-wrapper">
        <div className="contact-modal-container">
          <button className="close" onClick={props.close}>
            <Close />
          </button>
          <h2>
            Thank you <br /> for contacting us!
          </h2>
          <div className="content">
            <img src={icon} alt="icon" />
            <p>
              We have received your message. We will reach out to you as soon as
              possible.
            </p>
          </div>
          <Link
            to={"/"}
            onMouseDown={() => {
              props.close();
              navigate("/");
            }}
            className="link-btn"
          >
            back to medex
          </Link>
        </div>
      </div>
    </>
  );
};

export default ContactModal;
