import React from "react";
import YelowArrow from "../../../assets/svgs/YelowArrow";
import "./Deal.scss";

const Deal = () => {
  return (
    <div className="deal-wrapper">
      <div className="deal-container">
        <div className="left">
          <h1 className="primary-heading">
            You might <br /> be interested
            <span> in our </span>
          </h1>
        </div>
        <div className="right">
          <h1>Green</h1>
          <h1>Summer</h1>
          <h1>2023 deal </h1>
        </div>
      </div>
      <YelowArrow />
    </div>
  );
};

export default Deal;
