import React from "react";

const WhyMedex2 = () => {
  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="30.5004"
        cy="29.9997"
        r="18.7143"
        stroke="#24DC8F"
        strokeWidth="14"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 54.8853V59.9998H37V54.8855C39.3088 54.2841 41.4914 53.37 43.4998 52.191L47.1175 55.8088L56.3099 46.6164L52.692 42.9985C53.8708 40.9904 54.7848 38.8082 55.3861 36.4998H60.5V23.4998H55.3861C54.7848 21.1909 53.8706 19.0084 52.6916 17.0001L56.309 13.3827L47.1166 4.1903L43.499 7.80792C41.4909 6.62915 39.3085 5.71516 37 5.1139V-0.000244141H24V5.11412C21.6917 5.7154 19.5096 6.62936 17.5016 7.80806L13.8835 4.18995L4.69113 13.3823L8.30912 17.0003C7.13018 19.0086 6.21606 21.191 5.61472 23.4998H0.5V36.4998H5.61476C6.21602 38.8081 7.12996 40.9903 8.30866 42.9982L4.6902 46.6167L13.8826 55.8091L17.5008 52.1908C19.509 53.3698 21.6914 54.2839 24 54.8853ZM24 54.8853C26.0763 55.4261 28.2547 55.714 30.5004 55.714C32.7458 55.714 34.9239 55.4262 37 54.8855V45.6912L43.4998 52.191C47.2933 49.964 50.4651 46.7921 52.692 42.9985L46.1933 36.4998H55.3861C55.9269 34.4235 56.2147 32.2452 56.2147 29.9997C56.2147 27.7542 55.9269 25.5759 55.3861 23.4998H46.1919L52.6916 17.0001C50.4646 13.2066 47.2926 10.0348 43.499 7.80792L37 14.3069V5.1139C34.9239 4.57319 32.7458 4.2854 30.5004 4.2854C28.2547 4.2854 26.0763 4.57327 24 5.11412V14.3064L17.5016 7.80806C13.708 10.035 10.5361 13.2068 8.30912 17.0003L14.8085 23.4998H5.61472C5.07396 25.5759 4.78613 27.7542 4.78613 29.9997C4.78613 32.2452 5.07397 34.4235 5.61476 36.4998H14.8072L8.30866 42.9982C10.5355 46.7919 13.7073 49.9638 17.5008 52.1908L24 45.6917V54.8853Z"
        fill="#24DC8F"
      />
    </svg>
  );
};

export default WhyMedex2;
