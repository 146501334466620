import React from "react";

const EV = () => {
  return (
    <svg
      width="45"
      height="60"
      viewBox="0 0 45 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H41C43.2091 0 45 1.79086 45 4V56C45 58.2091 43.2091 60 41 60H4C1.79086 60 0 58.2091 0 56V4Z"
        fill="#24DC8F"
      />
      <rect
        x="12.8447"
        y="25.8135"
        width="20.2567"
        height="7.55601"
        fill="#121214"
      />
      <path
        d="M33.0596 17.9579L24.2317 25.8186L12.8975 25.8186L33.0596 8.04711L33.0596 17.9579Z"
        fill="#121214"
      />
      <path
        d="M12.9384 41.2342L21.7707 33.3678L33.1006 33.3677L12.9384 51.1392L12.9384 41.2342Z"
        fill="#121214"
      />
    </svg>
  );
};

export default EV;
