import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="not-found-wrapper">
      <div className="not-found-container">
        <h1>
          Sorry, <br />
          Page not found
        </h1>
        <Link to={"/"}>back to homepage</Link>
        <h2>
          <span>404</span>
        </h2>
      </div>
    </div>
  );
};

export default NotFound;
