import React from "react";

const AppreciatePower = () => {
  return (
    <svg
      width="60"
      height="63"
      viewBox="0 0 60 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1096_3251)">
        <rect x="15.1387" y="26.3818" width="30" height="10" fill="#24DC8F" />
        <path
          d="M44.9874 12.6881L29.5606 26.3885L15.1361 26.3885L44.9872 0.0590542L44.9874 12.6881Z"
          fill="#24DC8F"
        />
        <path
          d="M15.2875 50.0822L30.7143 36.3818L45.1388 36.3818L15.2877 62.7113L15.2875 50.0822Z"
          fill="#24DC8F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1096_3251">
          <rect width="60" height="62.7703" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppreciatePower;
