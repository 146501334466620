import React from "react";
import { Link } from "react-router-dom";
import WhatContact from "../../../../assets/svgs/WhatContact";
import "./IconLink.scss";

const IconLink = (props) => {
  return (
    <div className="icon">
      <Link to={props.link}>{props.content}</Link>
      <WhatContact />
    </div>
  );
};

export default IconLink;
