import React, { useEffect, useState } from "react";
import "./Chart.scss";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";
import { useLocation } from "react-router";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          {`${label} : `}
          <span style={{ fontWeight: "900" }}>{`${payload[0].value}`}</span> MW
        </p>
        <p className="label">
          <span style={{ fontWeight: "900" }}>{`${payload[1].value}`}</span>{" "}
          EUR/MWh
        </p>
        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        {/* <p className="desc">Anything you want can be displayed here.</p> */}
        {/* <Svg /> */}
      </div>
    );
  }

  return null;
};

const Chart = (props) => {
  const [posData, setposData] = useState({});
  const [widthWin, setWidthWin] = useState(0);
  const { pathname } = useLocation();
  const [tooltip, setTooltip] = useState(false);
  const data = props.data;

  useEffect(() => {
    if (pathname.includes("platform")) return;
    function handleResize() {
      setWidthWin(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [pathname, widthWin]);

  return (
    <div
      id="find-me"
      style={{
        height: "calc(100vh - 560px)",
        paddingTop: "70px",
      }}
    >
      <ResponsiveContainer width="100%">
        <ComposedChart
          width={400}
          height={300}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="0 0"
            stroke="#E6EAE8"
            vertical={false}
            //   horizontalPoints={[100, 300, 500, 700]}
            horizontal={true}
          />
          <XAxis
            dataKey="hours"
            //   interval={4}
            ticks={["H1", "H6", "H12", "H18", "H24"]}
            tickLine={false}
            axisLine={{ stroke: "#f5f5f5" }}
            stroke="#24DC8F"
          />
          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{
              outline: "none",
              visibility: `${tooltip ? "visible" : "hidden"}`,
            }}
            position={{ x: posData.x - 55, y: posData.y - 70 }}
          />
          {/* <Legend verticalAlign="top" align="left" height={36} /> */}
          <Bar
            radius={[10, 10, 0, 0]}
            dataKey="mwVolume"
            barSize={widthWin < 768 ? (widthWin < 370 ? 4 : 6) : 10}
            fill="#C9D1CE"
            yAxisId="left"
            legendType="rect"
            name="mwVolume"
            onMouseOver={(data) => {
              setposData(data);
            }}
          >
            {data.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  id={`${entry.name}`}
                  onMouseEnter={(e) => {
                    setTooltip(true);
                  }}
                  onMouseLeave={(e) => {
                    setTooltip(false);
                  }}
                />
              );
            })}
          </Bar>
          <Line
            dot={false}
            activeDot={false}
            strokeWidth={widthWin < 370 ? 4 : 6}
            strokeLinecap="round"
            type="monotone"
            dataKey="value"
            stroke="#24DC8F"
            yAxisId="right"
            legendType="rect"
            name="price"
            onMouseEnter={(e) => {
              setTooltip(true);
            }}
            onMouseLeave={(e) => {
              setTooltip(false);
            }}
          />
          <YAxis
            //   tickLine={false}
            yAxisId="left"
            axisLine={{ stroke: "#f5f5f5" }}
            //   unit="K"
            // domain={[5, "dataMax + 100"]}
            // tickCount={6}
            stroke="#8B8F8E"
          />
          <YAxis
            //   tickLine={false}
            yAxisId="right"
            orientation="right"
            stroke="#24DC8F"
            axisLine={{ stroke: "#f5f5f5" }}
            //   unit="K"
            // domain={[5, "dataMax + 10"]}
            tickCount={4}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
