import React from "react";
import "./Micro.scss";

const Micro = () => {
  return (
    <div className="solar-micro-wrapper">
      <div className="solar-micro-container">
        <h1 className="primary-heading">
          <span>Micro</span>production
        </h1>
        <ul>
          <li>Tired of being just a price taker? </li>
          <li>
            Or are you just thinking about generating renewable energy at your
            home?{" "}
          </li>
          <li>
            But don’t want strangers to mess with your roof and want to do it on
            your own?
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Micro;
