import React from "react";

const EmissionReduction = () => {
  return (
    <svg
      width="58"
      height="61"
      viewBox="0 0 58 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5238 5.27536C15.5237 7.77597 10.5238 16.776 10.5238 22.276C5.02384 23.276 2.02348 26.2759 0.523662 30.7759C-0.976152 35.2759 1.02349 40.2777 3.02361 42.7759C-0.476074 46.7759 -1.64881 52.7716 5.02384 57.7737C11.6965 62.7759 19.5239 56.7759 19.5239 56.7759C19.5239 56.7759 35.5143 65.7505 48.5 54.2632C61.4857 42.7759 54.5 25.2632 54.5 25.2632C54.5 25.2632 60.0239 19.2759 56.5237 12.7759C52.1654 4.68239 42.5238 7.27595 42.5238 7.27595C42.5238 7.27595 33.524 2.77475 24.5238 5.27536Z"
        fill="#24DC8F"
      />
      <circle cx="6.5" cy="12.7632" r="3.5" fill="#24DC8F" />
      <circle cx="55.5" cy="54.7632" r="2.5" fill="#24DC8F" />
      <circle cx="13" cy="2.26318" r="2" fill="#24DC8F" />
      <rect x="26" y="17.2632" width="8" height="22" fill="#121214" />
      <path d="M38 39.2632H22L30 48.2632L38 39.2632Z" fill="#121214" />
    </svg>
  );
};

export default EmissionReduction;
