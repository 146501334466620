import React from "react";

const WhyMedex3 = () => {
  return (
    <svg
      width="47"
      height="60"
      viewBox="0 0 47 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1742_8403)">
        <path
          d="M23.5 59.9997V6.52148"
          stroke="#24DC8F"
          strokeWidth="10"
          stroke-linejoin="round"
        />
        <path
          d="M41.5 59.9997V28.6953"
          stroke="#24DC8F"
          strokeWidth="10"
          stroke-linejoin="round"
        />
        <path
          d="M5.5 59.9998V40.4346"
          stroke="#24DC8F"
          strokeWidth="10"
          stroke-linejoin="round"
        />
        <path
          d="M14.5 18.9998H32.5L23.5 2.99976L14.5 18.9998Z"
          stroke="#24DC8F"
          strokeWidth="6"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1742_8403">
          <rect
            width="46"
            height="60"
            fill="white"
            transform="translate(0.5 -0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhyMedex3;
