import * as api from "../api/contact";

export const sendEmail = async (data) => {
  try {
    const response = await api.sendEmail(data);
    return response;
  } catch (error) {
    
    return error;
  }
};
