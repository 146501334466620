import React from "react";

const Arrow = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.04058 9.76694C5.41696 10.4732 6.42919 10.4732 6.80557 9.76694L11.135 1.64317C11.49 0.977071 11.0073 0.172852 10.2526 0.172852L1.5936 0.172852C0.838817 0.172852 0.356117 0.977073 0.711105 1.64317L5.04058 9.76694Z"
        fill="#3141BC"
      />
    </svg>
  );
};

export default Arrow;
