import React from "react";
import "./Initiative.scss";
import outlet from "../../../assets/backgrounds/outlet.png";

const Initiative = () => {
  return (
    <div className="go-green-initiative-wrapper">
      <div className="go-green-initiative-container">
        <div className="image"></div>
        <div className="text">
          <h1 className="primary-heading">Go green Initiative</h1>
          <p>
            Our Go Green Initiative will equip Montenegro with 100 superfast
            Electric Vehicle Chargers by the year 2030, and they will be
            distributed through all the major cities of our country to ensure
            everybody has access to green renewable power sources.
          </p>
          <p>The driving forces behind our Go Green Initiative will be:</p>
          <ul>
            <li>our leadership in new technologies, </li>
            <li>
              enabling individual households to create and sell their own energy
              through our PPA offers,{" "}
            </li>
            <li>investment in Montenegro’s EV charger grid, </li>
            <li>
              and innovative approach to the transaction of electric energy.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Initiative;
