import React, { useEffect, useMemo, useRef, useState } from "react";
import Pledge from "../../components/GoGreen/Pledge";
import DarkText from "../../components/GoGreen/DarkText";
import GoGreenFirst from "../../components/GoGreen/GoGreenFirst";
import Initiative from "../../components/GoGreen/Initiative";
import GoGreenLink from "../../components/GoGreen/Link";
import "./GoGreen.scss";
import ServingCommunity from "../../components/GoGreen/ServingCommunity";
import EVCharger from "../../components/GoGreen/EVCharger";
import ChargerTest from "../../components/GoGreen/ChargerTest";

const GoGreen = (props) => {
  const [endAnimation, setEndAnimation] = useState(false);
  let ending = false;
  let ending2 = false;
  const ref = useRef();
  const ref2 = useRef();
  // if (useIsInViewport(ref)) ending = true;

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <div className="go-green-wrapper">
      <GoGreenFirst />
      <DarkText />
      <Pledge />
      <Initiative />
      <ChargerTest images={props.images} />
      {/* <EVCharger images={props.images} end={ending} end2={ending2} /> */}
      <div ref={ref}>
        <ServingCommunity />
      </div>
      <GoGreenLink />
    </div>
  );
};

export default GoGreen;

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
