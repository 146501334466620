import React from "react";

const PPA = () => {
  return (
    <svg
      width="86"
      height="60"
      viewBox="0 0 86 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1096_3249)">
        <path
          d="M0.5 47V14H16.2121C17.7674 14 19.164 14.2679 20.402 14.8037C21.6399 15.308 22.6873 16.0172 23.5444 16.9312C24.4331 17.8453 25.1156 18.9642 25.5917 20.288C26.0678 21.5802 26.3059 22.9986 26.3059 24.543C26.3059 26.1189 26.0678 27.553 25.5917 28.8453C25.1156 30.1375 24.4331 31.2407 23.5444 32.1547C22.6873 33.0688 21.6399 33.7937 20.402 34.3295C19.164 34.8338 17.7674 35.086 16.2121 35.086H7.73707V47H0.5Z"
          fill="#24DC8F"
        />
        <path
          d="M30.127 47V14H45.8391C47.3944 14 48.791 14.2679 50.029 14.8037C51.2669 15.308 52.3144 16.0172 53.1714 16.9312C54.0602 17.8453 54.7426 18.9642 55.2187 20.288C55.6948 21.5802 55.9329 22.9986 55.9329 24.543C55.9329 26.1189 55.6948 27.553 55.2187 28.8453C54.7426 30.1375 54.0602 31.2407 53.1714 32.1547C52.3144 33.0688 51.2669 33.7937 50.029 34.3295C48.791 34.8338 47.3944 35.086 45.8391 35.086H37.3641V47H30.127Z"
          fill="#24DC8F"
        />
        <path
          d="M78.1687 47L75.7881 39.1519H64.6944L62.3138 47H54.9815L65.9323 14H74.8834L85.6914 47H78.1687Z"
          fill="#24DC8F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1096_3249">
          <rect
            width="85.1914"
            height="60"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PPA;
