import React from "react";
import WhoWeAreText from "../../components/WhoWeAre/WhoWeAreText";
import WhyMedex from "../../components/WhoWeAre/WhyMedex";
import "./WhoWeAre.scss";
import DiscoverPowers from "../../components/WhoWeAre/DiscoverPowers";
import BuildFuture from "../../components/WhoWeAre/BuildFuture";
import PartnerMedex from "../../components/WhoWeAre/PartnerMedex";
import OurTeam from "../../components/WhoWeAre/OurTeam";

const WhoWeAre = () => {
  return (
    <div className="who-we-are-wrapper">
      <WhoWeAreText />
      <OurTeam />
      <WhyMedex />
      <DiscoverPowers />
      <BuildFuture />
      <PartnerMedex />
    </div>
  );
};

export default WhoWeAre;
