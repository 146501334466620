import React from "react";
import { Link } from "react-router-dom";
import WhatContact from "../../../assets/svgs/WhatContact";
import "./Contact.scss";

const Contact = () => {
  return (
    <div className="what-we-do-contact-wrapper">
      <div className="what-we-do-contact-container">
        <div className="icon">
          <Link to={"/contact"}>
            Contact us and <br /> discover your powers!
          </Link>
          <WhatContact />
        </div>
        <h1>
          Don't underestimate your assets, <br /> and the value of your power.
        </h1>
      </div>
    </div>
  );
};

export default Contact;
