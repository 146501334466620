import React from "react";

const WhatContact = () => {
  return (
    <svg
      width="421"
      height="353"
      viewBox="0 0 421 353"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M216.124 26.5552C187.72 26.5552 159.954 34.9645 136.336 50.7198C112.719 66.475 94.3117 88.8686 83.4418 115.069C72.572 141.269 69.7279 170.098 75.2693 197.912C80.8107 225.726 94.4887 251.275 114.574 271.327C134.658 291.38 160.248 305.036 188.107 310.568C215.965 316.101 244.841 313.261 271.083 302.409C297.325 291.557 319.755 273.179 335.536 249.599C351.316 226.02 359.739 198.298 359.739 169.939C359.739 151.11 356.024 132.465 348.807 115.069C341.59 97.6725 331.011 81.8659 317.675 68.5514C304.339 55.237 288.507 44.6754 271.083 37.4697C253.659 30.2639 234.984 26.5552 216.124 26.5552ZM216.124 264.85C197.323 264.85 178.943 259.284 163.31 248.855C147.677 238.426 135.493 223.603 128.297 206.26C121.102 188.917 119.22 169.834 122.888 151.423C126.556 133.012 135.61 116.101 148.905 102.827C162.199 89.5538 179.138 80.5145 197.578 76.8523C216.019 73.1902 235.133 75.0697 252.504 82.2533C269.874 89.4368 284.721 101.602 295.167 117.21C305.612 132.818 311.188 151.168 311.188 169.939C311.19 182.404 308.733 194.747 303.956 206.263C299.179 217.779 292.177 228.243 283.349 237.056C274.521 245.87 264.041 252.861 252.506 257.63C240.972 262.399 228.609 264.852 216.124 264.85Z"
        fill="#24DC8F"
      />
      <path
        d="M49.0997 132.006L187.247 0H136.777L0 132.006H49.0997Z"
        fill="#24DC8F"
      />
      <path
        d="M282.083 352.597L420.231 220.591H369.76L232.983 352.597H282.083Z"
        fill="#24DC8F"
      />
    </svg>
  );
};

export default WhatContact;
