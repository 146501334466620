import React from "react";
import Contact from "../../components/WhatWeDo/Contact";
import Icons from "../../components/WhatWeDo/Icons";
import Map from "../../components/WhatWeDo/Map";
import You from "../../components/WhatWeDo/You";
import "./WhatWeDo.scss";

const WhatWeDo = () => {
  return (
    <div className="what-we-do-wrapper">
      <Map />
      <Icons />
      <You />
      <Contact />
    </div>
  );
};

export default WhatWeDo;
