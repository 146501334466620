import React from "react";
import SingleGreenLine from "../../../assets/svgs/SingleGreenLine";
import "./DiscoverPowers.scss";
import WhyMedexLongM from "../../../assets/svgs/WhyMedexLongM";

const DiscoverPowers = () => {
  return (
    <div className="discover-powers-wrapper">
      <div className="discover-powers-container">
        <div className="text">
          <p>
            <span>At the heart of our mission</span> is the empowerment of
            entrepreneurs and traders in green energy by providing them with:
          </p>
          <ul>
            <li>
              a competitive edge by strengthening their core trading
              capabilities,{" "}
            </li>
            <li>new opportunities,</li>
            <li>access to capital and markets and IT, </li>
            <li>risk advisory, </li>
            <li>and so much more.</li>
          </ul>
        </div>
        <div className="image">
          <SingleGreenLine />
          <h5>
            Discover <br /> your powers
          </h5>
        </div>
      </div>
    </div>
  );
};

export default DiscoverPowers;
