import React from "react";

const DemandResponse = () => {
  return (
    <svg
      width="62"
      height="60"
      viewBox="0 0 62 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1096_3247)">
        <circle cx="45.5" cy="30" r="16" fill="#24DC8F" />
        <path d="M8 14H31.5L27.5 22H4L8 14Z" fill="#24DC8F" />
        <path d="M2 26H25.5L23.5 30L25.5 34H2L0 30L2 26Z" fill="#24DC8F" />
        <path d="M4 38H27.5L31.5 46H8L4 38Z" fill="#24DC8F" />
      </g>
      <defs>
        <clipPath id="clip0_1096_3247">
          <rect width="61.5" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DemandResponse;
