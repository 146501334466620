import React, { useEffect, useState } from "react";
import "./BarChart.scss";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useLocation } from "react-router";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ zIndex: 1000 }}>
        <p className="label">
          {`${label} : `}
          <span style={{ fontWeight: "900" }}>{`${payload[0].value}`}</span> MW
        </p>

        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        {/* <p className="desc">Anything you want can be displayed here.</p> */}
        {/* <Svg /> */}
      </div>
    );
  }

  return null;
};

const BarChartComp = (props) => {
  const [posData, setposData] = useState({});

  const [widthWin, setWidthWin] = useState(0);
  const { pathname } = useLocation();
  const [tooltip, setTooltip] = useState(false);
  const data = props.data;

  useEffect(() => {
    if (pathname.includes("platform")) return;
    function handleResize() {
      setWidthWin(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [pathname, widthWin]);

  return (
    <div
      style={{
        width: "calc(100vw - 16px) !important",
        height: "calc(100vh - 560px)",
        paddingTop: "70px",
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid
            strokeDasharray="0 0"
            stroke="#E6EAE8"
            vertical={false}
            //   horizontalPoints={[100, 300, 500, 700]}
            horizontal={true}
          />
          <XAxis
            dataKey="hours"
            // interval={4}
            ticks={["H1", "H6", "H12", "H18", "H24"]}
            tickLine={false}
            axisLine={{ stroke: "#f5f5f5" }}
            stroke="#24DC8F"
          />
          <YAxis //   tickLine={false}
            stroke="#8B8F8E"
            axisLine={{ stroke: "#f5f5f5" }}
            //   unit="K"
            // domain={[5, "dataMax "]}
            tickCount={5}
            orientation="right"
          />
          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{
              outline: "none",
              zIndex: 1000,
              visibility: `${tooltip ? "visible" : "hidden"}`,
            }}
            position={{ x: posData.x - 55, y: posData.y - 50 }}
            cursor={{ fill: "transparent" }}
          />
          {/* <Legend /> */}
          <Bar
            radius={[10, 10, 0, 0]}
            dataKey="mwVolume"
            barSize={widthWin < 768 ? (widthWin < 370 ? 4 : 6) : 10}
            fill="#C9D1CE"
            // yAxisId="left"
            legendType="rect"
            name="mwVolume"
            onMouseOver={(data) => {
              setposData(data);
            }}
          >
            {data.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  id={`${entry.name}`}
                  onMouseEnter={(e) => {
                    setTooltip(true);
                  }}
                  onMouseLeave={(e) => {
                    setTooltip(false);
                  }}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComp;
