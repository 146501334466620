import { useLocation, Navigate, Outlet } from "react-router";
import { useState } from "react";
import { useSelector } from "react-redux";

const Protect = () => {
  const userStore = useSelector((store) => store.auth);
  // const [authorized, setAuthorized] = useState(
  //   userStore.roles.findIndex((i) => i.id === 2) !== -1 ? true : false
  // );
  const [authorized, setAuthorized] = useState(
    userStore.isLoggedIn !== false ? true : false
  );
  const location = useLocation();

  if (authorized) {
    return <Outlet />;
  } else {
    return <Navigate to={"/sign-in"} state={{ from: location }} replace />;
  }
};

export default Protect;
