import React from "react";
import ChartWrap from "./ChartWrap";
import DayAhead from "./DayAhead";
import "./Statistics.scss";

const Statistics = () => {
  return (
    <div className="landing-page-statistics-wrapper">
      <div className="landing-page-statistics-container">
        <div className="first-part">
          <h1 className="primary-heading">Medex DAM Statistics </h1>
          <ChartWrap />
        </div>
        <div className="second-part">
          <DayAhead />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
