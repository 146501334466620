import React from "react";
import Deal from "../../components/Microproduction/Deal";
import DealSignUp from "../../components/Microproduction/DealSignUp";
import Micro from "../../components/Microproduction/Micro";
import YouGet from "../../components/Microproduction/YouGet";
import "./Solar.scss";

const Solar = () => {
  return (
    <div className="solar-wrapper">
      <Micro />
      <Deal />
      <YouGet />
      <DealSignUp />
    </div>
  );
};

export default Solar;
