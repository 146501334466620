import React from "react";
import "./You.scss";

const You = () => {
  return (
    <div className="you-wrapper">
      <div className="you-container">
        <div className="left">
          <h1 className="primary-heading">If you are:</h1>
        </div>
        <div className="right">
          <ul>
            <li>
              <h3>a micro-producer</h3>
              <p>
                searching for a fair deal to earn money on your green energy
              </p>
            </li>
            <li>
              <h3>a small IPP</h3>
              <p>tired of natural and imposed monopols</p>
            </li>
            <li>
              <h3>smart energy consumer</h3>
              <p>willing to earn on your flexibility</p>
            </li>
            <li>
              <h3>simply an energy trader</h3>
              <p>looking for a good deal</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default You;
