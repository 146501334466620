import React from "react";
import "./DarkText.scss";
import EV from "../../../assets/svgs/EV";
import GreenEnergy from "../../../assets/svgs/GreenEnergy";
import EmissionReduction from "../../../assets/svgs/EmissionReduction";

const DarkText = () => {
  return (
    <div className="go-green-dark-text-wrapper">
      <div className="go-green-dark-text-container">
        <div className="go-green-dark-text-cards">
          <div className="go-green-dark-text-card">
            <EV />
            <p>
              100 Electric <br /> vehicle chargers
            </p>
          </div>
          <div className="go-green-dark-text-card">
            <GreenEnergy />
            <p>
              Genuine <br /> green energy
            </p>
          </div>
          <div className="go-green-dark-text-card">
            <EmissionReduction />
            <p>
              CO&sup2; emission <br /> reduction
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DarkText;
