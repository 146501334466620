import React from "react";
import Aggregating from "../../../assets/svgs/Aggregating";
import AppreciatePower from "../../../assets/svgs/AppreciatePower";
import Balancing from "../../../assets/svgs/Balancing";
import BindingOffers from "../../../assets/svgs/BindingOffers";
import DemandResponse from "../../../assets/svgs/DemandResponse";
import MarketStudies from "../../../assets/svgs/MarketStudies";
import PPA from "../../../assets/svgs/PPA";
import "./Icons.scss";

const Icons = () => {
  return (
    <div className="what-we-do-icons-wrapper">
      <div className="what-we-do-icons-container">
        <h1 className="primary-heading">
          we design <br /> for you special deals:
        </h1>
        <div className="icons">
          <div className="row-items">
            <div className="item">
              <Balancing />
              <h3>Balancing</h3>
            </div>
            <div className="item">
              <Aggregating />
              <h3>Aggregating</h3>
            </div>
            <div className="item">
              <DemandResponse />
              <h3>Demand Response</h3>
            </div>
            <div className="item">
              <BindingOffers />
              <h3>Binding Offers</h3>
            </div>
          </div>
          <div className="row-items">
            <div className="item">
              <PPA />
              <h3>Indicative Offers PPA</h3>
            </div>
            <div className="item">
              <MarketStudies />
              <h3>Investment Decision Making </h3>
            </div>
            <div className="item">
              <AppreciatePower />
              <h3>Guarantees of Origin</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Icons;
