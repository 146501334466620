import React from "react";
import "./PartnerMedex.scss";
import { useNavigate } from "react-router";

const PartnerMedex = () => {
  const navigate = useNavigate();
  return (
    <div className="partner-medex-wrapper">
      <div className="partner-medex-container">
        <p>
          <span>Partner with Medex</span> and make the most of the power you
          produce or use from green solutions and renewable sources.
        </p>
        <button className="btn" onClick={() => navigate("/contact")}>
          Partner with Medex
        </button>
      </div>
    </div>
  );
};

export default PartnerMedex;
