import React from "react";
import { Link } from "react-router-dom";
import Email from "../../../../assets/svgs/Email";
import Facebook from "../../../../assets/svgs/Facebook";
import Home from "../../../../assets/svgs/Home";
import Instagram from "../../../../assets/svgs/Instagram";
import LetterM from "../../../../assets/svgs/LetterM";
import LinkedIn from "../../../../assets/svgs/LinkedIn";
import Logo from "../../../../assets/svgs/Logo";
import Phone from "../../../../assets/svgs/Phone";
import "./Footer.scss";
import home from "../../../../assets/icons/home.png";
import email from "../../../../assets/icons/email.png";
import phone from "../../../../assets/icons/phone.png";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="footer-icons">
          {/* <Link to={"/"}>
            {" "}
            <Facebook />
          </Link>
          <Link to={"/"}>
            <Instagram />
          </Link> */}
          <a
            href={"https://www.linkedin.com/company/medex-electric/"}
            target="_blank"
          >
            <LinkedIn />
          </a>
        </div>
        <div className="footer-links">
          <div className="footer-links-first">
            <ul className="footer-list">
              <li className="title">Find Us</li>
              <li>
                {/* <Home /> */}
                <img src={home} alt="address" />
                Svetlane Kane Radevic 1, <br /> 2nd floor
              </li>
              <li>
                {/* <Email /> */}
                <img src={email} alt="email" />
                contact@medex.me
              </li>
              <li>
                {/* <Phone /> */}
                <img src={phone} alt="phone" />
                +382 20 513 333
              </li>
            </ul>
          </div>
          <div className="footer-links-second">
            <ul className="footer-list">
              <li className="title">Useful Links</li>
              <li>
                <Link to={"/"}>FAQs</Link>
              </li>
              <li>
                <Link to={"/"}>Terms of Use</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <span>LEI code: 894500QVK717O4IAOC42 </span>
      <Logo />
      <LetterM />
    </div>
  );
};

export default Footer;
