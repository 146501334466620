import React from "react";
import IconLink from "../UI/IconLink/IconLink";
import "./DealSignUp.scss";

const DealSignUp = () => {
  return (
    <div className="deal-sign-up-wrapper">
      <div className="deal-sign-up-container">
        <h2>
          You can find out if you’re eligible <br /> and sign up for the deal
          here.
        </h2>
        <IconLink content={"Sign up for the deal"} link="/contact" />
      </div>
    </div>
  );
};

export default DealSignUp;
