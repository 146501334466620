import React from "react";

const FixedPrice = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1098_2642)">
        <path
          d="M0 18.5319H47.1154V42.0945C47.1154 45.4082 44.4291 48.0945 41.1154 48.0945H6C2.68629 48.0945 0 45.4082 0 42.0945V18.5319Z"
          fill="#24DC8F"
        />
        <mask id="path-2-inside-1_1098_2642" fill="white">
          <path d="M6.4668 0.0552979H40.6485V14.8366H6.4668V0.0552979Z" />
        </mask>
        <path
          d="M6.4668 0.0552979V-7.9447H-1.5332V0.0552979H6.4668ZM40.6485 0.0552979H48.6485V-7.9447H40.6485V0.0552979ZM6.4668 8.0553H40.6485V-7.9447H6.4668V8.0553ZM32.6485 0.0552979V14.8366H48.6485V0.0552979H32.6485ZM14.4668 14.8366V0.0552979H-1.5332V14.8366H14.4668Z"
          fill="#24DC8F"
          mask="url(#path-2-inside-1_1098_2642)"
        />
      </g>
      <defs>
        <clipPath id="clip0_1098_2642">
          <rect
            width="47.1154"
            height="48.0392"
            fill="white"
            transform="translate(0 0.0552979)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FixedPrice;
