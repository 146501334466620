import React from "react";

const WhyMedex1 = () => {
  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 59.9998C47.0685 59.9998 60.5 46.5683 60.5 29.9998C60.5 13.4312 47.0685 -0.000244141 30.5 -0.000244141C13.9315 -0.000244141 0.5 13.4312 0.5 29.9998C0.5 46.5683 13.9315 59.9998 30.5 59.9998ZM30.4649 42.6967L48.7462 20.6655L44.1288 16.834L27.8758 36.421L16.6068 25.9291L12.5182 30.3204L26.112 42.9767C26.7161 43.5391 27.5252 43.8278 28.3488 43.7748C29.1724 43.7218 29.9379 43.3319 30.4649 42.6967Z"
        fill="#24DC8F"
      />
    </svg>
  );
};

export default WhyMedex1;
