import axios from "axios";

const url = "https://api.medex.me/api/Email/send";

let headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const sendEmail = (data) => {
  return axios({
    method: "post",
    url: url,
    data: {
      email: data.email,
      name: data.name,
      surname: data.surname,
      phone: data.phone,
      message: data.message,
      subject: data.subject,
    },
    headers: headers,
  });
};
