import React from "react";
import "./GoGreenFirst.scss";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import poster from "../../../assets/car-preview.png";

const GoGreenFirst = () => {
  return (
    <div className="go-green-first-wrapper">
      <video
        src="videos/car.mov"
        poster={poster}
        autoPlay
        loop
        muted="muted"
        playsInline
      ></video>

      <div className="go-green-first-container">
        <h1 className="primary-heading">Go green</h1>
        <p>
          Initiative for healthier and sustainable future. Establishment of the
          grid for storage, use and dispersion of green energy in Montenegro.
          Where everything drives green.
        </p>
        <Link className="btn" to={"/contact"}>
          Get in touch
        </Link>
      </div>
    </div>
  );
};

export default GoGreenFirst;
